import {
  ChangeDetectorRef,
  Component,
  Inject,
  OnDestroy,
  OnInit, PLATFORM_ID,
  TemplateRef,
  ViewChild,
  ViewContainerRef
} from '@angular/core';
import {ChatWidgetHeaderComponent} from "../partrials/header/chat-widget-header/chat-widget-header.component";
import {
  ConversationLayoutComponent
} from "../partrials/conversation-layout/conversation-layout.component";
import {
  MessageItemComponent
} from "@revolve-app/app/theme/components/chat-components/partrials/message-item/message-item.component";
import {
  ContactItemComponent
} from "@revolve-app/app/theme/components/chat-components/partrials/contact-item/contact-item.component";
import {
  SearchContactComponent
} from "@revolve-app/app/theme/components/chat-components/partrials/search-contact/search-contact.component";
import {
  WriteMessageInputComponent
} from "@revolve-app/app/theme/components/chat-components/partrials/write-message-input/write-message-input.component";
import {
  ChatHeaderComponent
} from "@revolve-app/app/theme/components/chat-components/partrials/header/chat-header/chat-header.component";
import {Store} from "@ngrx/store";
import {
  openRoomByIdRequestStart,
  openRoomRequestStart, roomListPaginationUpdate,
  roomsListRequestStart
} from "@revolve-app/app/core/features/chat/store/rooms/rooms.actions";
import {RoomsModule} from "@revolve-app/app/core/features/chat/store/rooms/rooms.module";
import {ButtonComponent, TButtonsSizes} from "@revolve-app/app/shared/forms/buttons/button/button.component";
import {isBrowser} from "@revolve-app/app/core/utils/is.browser";
import {
  selectChatRooms, selectRoomPagination, selectSelectedRoom
} from "@revolve-app/app/core/features/chat/store/rooms/rooms.reducer";
import {MicroService} from "@revolve/meta-data/metadata/enums/MicroService";
import {RoomType} from "@revolve/meta-data/roots/chat-microservice/metadata";
import {ModalService} from "@revolve-app/app/shared/modal/modal.service";
import {selectIsLoggedIn, selectMeAccount} from "@revolve-app/app/core/features/accounts/store/me/me.reducer";
import {RoomsForm} from "@revolve-app/app/core/features/chat/forms/rooms.form";
import {FormGroup, ReactiveFormsModule} from "@angular/forms";
import {InputComponent} from "@revolve-app/app/shared/forms/text-inputs/input/input.component";
import {selectMessages} from "@revolve-app/app/core/features/chat/store/message/message.reducer";
import {messageListFilterUpdate} from "@revolve-app/app/core/features/chat/store/message/message.actions";
import {MeModule} from "@revolve-app/app/core/features/accounts/store/me/me.module";
import {
  selectIpSession
} from "@revolve-app/app/core/features/platform-settings/store/platform-settings/platform-settings.reducer";
import {Pagination} from "@revolve-app/app/core/utils/pagination";

@Component({
  selector: 'app-chat-big',
  standalone: true,
  templateUrl: './responsive-widget.component.html',
  imports: [
    MessageItemComponent,
    ContactItemComponent,
    RoomsModule,
    SearchContactComponent,
    WriteMessageInputComponent,
    ChatWidgetHeaderComponent,
    ConversationLayoutComponent,
    ChatHeaderComponent,
    ButtonComponent,
    InputComponent,
    ReactiveFormsModule,
    MeModule
  ],
  providers:[
    RoomsForm,
  ]
})
export class ResponsiveWidgetComponent extends Pagination implements  OnInit, OnDestroy{

  @ViewChild('infoModal', { static: true }) infoModal!: TemplateRef<Element>;
  @ViewChild('vcr', { static: true, read: ViewContainerRef }) vcr!: ViewContainerRef;


  roomsSelector$: any;
  isLoggedInSelector$: any;
  roomSelector$: any;
  isMeUserSelector$: any;
  currentIpSelector$: any;
  ipSession: any
  rooms: any = [];
  selectedRoom: any = null
  isLoggedIn: boolean = false
  linkToSubscribe: any = [];
  forUUID = 'd5b7cc96-9031-4182-9ab2-91905c5c4312'
  forService = MicroService.ORDER
  infoModalForm: FormGroup;
  localUser: any
  constructor(private form: RoomsForm,
              private modalService: ModalService,
              @Inject(PLATFORM_ID) platformId: Object) {
    super(roomListPaginationUpdate, roomsListRequestStart, selectRoomPagination, platformId)
    this.init()
    this.infoModalForm = this.form.infoModal()
    if (isBrowser()) {
      this.store.dispatch(roomsListRequestStart({}))
    }
  }

  ngOnInit(): void {
    this.roomsSelector$ = this.store.select(selectChatRooms)
    this.isLoggedInSelector$ = this.store.select(selectIsLoggedIn)
    this.isMeUserSelector$ = this.store.select(selectMeAccount)
    this.roomSelector$ = this.store.select(selectSelectedRoom)
    this.currentIpSelector$ = this.store.select(selectIpSession)
    this.linkToSubscribe.push(this.roomSelector$.subscribe((data:any) => {
      this.selectedRoom = data
    }));
    this.linkToSubscribe.push(this.roomsSelector$.subscribe((data:any) => {
      if (!this.$data) {
        this.$data = [];
      }
      const newData = [...data];
      if(!this.pagination.page ||  this.pagination.page === 1){
        this.$data = newData
      }else {
        this.$data = [...this.$data, ...newData]
      }


    }));
    this.linkToSubscribe.push(this.isLoggedInSelector$.subscribe((data:boolean) => {
      this.isLoggedIn = data
    }));
    this.linkToSubscribe.push(this.isMeUserSelector$.subscribe((data:boolean) => {
      this.localUser = data
    }));
    this.linkToSubscribe.push(this.currentIpSelector$.subscribe((data:any) => {
      this.ipSession = data
    }));

  }

  public openRoom(id?: string){
    if(!id && !this.isLoggedIn && !this.infoModalForm.valid){
      this.modalService.open(this.vcr, this.infoModal);
    }else if(!id){
      this.closeGetInfoModal()
      this.store.dispatch(openRoomRequestStart({data:{
          ...this.infoModalForm.value,
          type: RoomType.WITH_PLATFORM_ADMIN,
          forUUID: this.forUUID,
          forService: this.forService,
        }}))
    }else {
      this.store.dispatch(openRoomByIdRequestStart({id}))
    }
  }

  closeGetInfoModal(){
    this.modalService.close()
  }

  ngOnDestroy(): void {
    for (let i = 0; i < this.linkToSubscribe.length; i++) {
      this.linkToSubscribe[i].unsubscribe();
    }
  }

  onScroll(event: any) {
    const bottom = event.target.scrollHeight < event.target.scrollTop + event.target.clientHeight + 10;
    if (bottom) {
      this.nextPage()
    }
  }

  protected readonly TButtonsSizes = TButtonsSizes;
}
