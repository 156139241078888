import Session from "../../metadata/interfaces/Session";
import IpSession from "../../metadata/interfaces/IpSession";

export enum ErrorMessages {
    NEED_TO_WAIT = 'need_to_wait',
    EMAIL_EXISTS = 'email_exists',
    INVALID_VERIFICATION_CODE = 'invalid_verification_code',
    CANT_SEND_RESET_EMAIL = 'cant_send_reset_email',
    CANT_SAVE_NEW_PASSWORD = 'cant_save_new_password',
    INVALID_PASSWORD_OR_EMAIL = "invalid_password_or_email",
    EMAIL_NOT_VERIFIED = 'email_not_verified',
    USER_BLOCKED = 'user_blocked',
    EMAIL_IS_REQUIRED = 'email_is_required',
    INVALID_EMAIL_FORMAT = 'invalid_email_format',
    USER_IS_NOT_ACTIVATE_EMAIL = 'user_is_not_activate_email',
    INVALID_TOKEN = 'invalidToken',
    INVALID_EMAIL = "invalidEmail",
    INVALID_PASSWORD = 'invalidPassword',
    INVALID_ID = 'invalidId',
    INVALID_ACCOUNT_MEMBER_ID = 'invalidAccountMemberId'
}

export enum UserRolesEnum {
    SUPER_ADMIN,
    PLATFORM_ADMIN,
    PLATFORM_MANAGER,
    CUSTOMER,
}

export enum UserStatus {
    NEW = 0,
    ACTIVE = 1,
    BLOCKED= 2,
    DELETED= 99,
}
export interface AccountMinimalResponse {
    email: string;
    role: UserRolesEnum;
    status: UserStatus;
    _uuid: string
    id: string,
    displayName: string
}
export interface AccountMeResponse {
    account: AccountMinimalResponse;
    ipSession: IpSession
}
export interface SuccessLoginResponse {
    token: string;
    account?: AccountMinimalResponse;
    session: Session;
}

export interface signInWithProviderResponse{
    displayName: string,
    openId: string,
    provider: string,
    status: number,
    lastname?: string
    firstname?: string
    email?: string
    platformUUID?: string
}
