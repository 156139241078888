import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import * as MeActions from "@revolve-app/app/core/features/accounts/store/me/me.actions";
import {catchError, map, mergeMap} from "rxjs/operators";
import {AccountService} from "@revolve-app/app/core/features/accounts/services/account.service";
import {of} from "rxjs";
import * as AuthActions from "@revolve-app/app/core/features/auth/store/auth/auth.actions";
import {StorageKeys, StorageService} from "@revolve-app/app/core/utils/storage.service";
import {UserRolesEnum} from "@revolve/meta-data/roots/account-microservice/metadata.errors";
import {Store} from "@ngrx/store";
import {
  getAllPlatformsRequestStart
} from "@revolve-app/app/core/features/platform-settings/store/platform-settings/platform-settings.actions";

@Injectable()
export class MeEffect {
  constructor(
    private actions$: Actions,
    private store: Store,
    private accountService: AccountService
  ) {}

  meRequestStart$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(MeActions.getMeStartRequest),
      mergeMap(() => {
        return this.accountService.me().pipe(
          map((payload) => {
            StorageService.setJsonItem(StorageKeys.USEROBJECT, payload.account);
            if(payload.account.role === UserRolesEnum.SUPER_ADMIN){
              this.store.dispatch(getAllPlatformsRequestStart())
            }
            return MeActions.getMeSuccessRequest({ payload });
          }),
          catchError((error) => of(AuthActions.signOutSuccess()))
        );
      })
    );
  });


}
