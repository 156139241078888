<div class="container mx-auto shadow-lg rounded-lg">
  <app-chat-header></app-chat-header>
  <div class="flex flex-row justify-between bg-white max-h-500">
    <div class="hidden md:flex flex-col w-2/5 border-r-2 overflow-y-auto"  (scroll)="onScroll($event)">
      <div class="border-b-2 py-4 px-2">
       <app-search-contact></app-search-contact>
      </div>
<!--      <app-button text="OpenRoom" i18n-text (onClick)='openRoom()' [size]="TButtonsSizes.MEDIUM_REGULAR" class='mt-5'>-->
<!--      </app-button>-->
      @for (room of $data; track $data) {
        <a class="pointer" (click)="openRoom(room._uuid)">
          <app-contact-item [room]="room" [user] = "localUser" [ipSession]="ipSession"></app-contact-item>
        </a>
      }


    </div>
    <a class="w-full md:px-5 flex flex-col justify-between">
      @if (selectedRoom){
        <app-conversation-layout></app-conversation-layout>
      }
    </a>
</div>
<ng-template #vcr></ng-template>

<ng-template #infoModal>
  <div class="modal-container">
    <form [formGroup]="infoModalForm" >
      <div class='mt-5 relative'>
        <app-input
          [formControlItem]="infoModalForm.controls['email']"
          placeholder="email"
          iconPrefix="icon icon-email text-secondary"
          type="text"
        ></app-input>
      </div>
      <div class='mt-5 relative'>
        <app-input
          [formControlItem]="infoModalForm.controls['displayName']"
          placeholder="displayName"
          iconPrefix="icon icon-name text-secondary"
          type="text"
        ></app-input>
      </div>
      <div class="footer content-center">
        <app-button text="Open room" i18n-text (onClick)='openRoom()' [size]="TButtonsSizes.MEDIUM_REGULAR" class='mt-5'>
        </app-button>
      </div>
    </form>

  </div>
</ng-template>
