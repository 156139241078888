import { NgModule } from '@angular/core';
import { provideState } from '@ngrx/store';
import { provideEffects } from '@ngrx/effects';
import {ME_FEATURE_NAME, meReducer} from "@revolve-app/app/core/features/accounts/store/me/me.reducer";
import {MeEffect} from "@revolve-app/app/core/features/accounts/store/me/me.effect";
import {AccountService} from "@revolve-app/app/core/features/accounts/services/account.service";

@NgModule({
  imports: [],
  providers: [
    provideState(ME_FEATURE_NAME, meReducer),
    provideEffects(MeEffect),
    AccountService
  ]
})
export class MeModule { }
